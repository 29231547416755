import React from "react";
import Swal from 'sweetalert2';
import Frame from 'react-frame-component';
import { BoldText, NormalText } from "../normalText/normalText";
import styles from "./normal.module.css";
import { InputText } from "../inputs/inputs";
import { Dispatach_Card_Navigation } from "../../redux/actions/navigations";
import { useDispatch } from "react-redux";
import { useRef } from "react";
import { useEffect, useState } from "react";
import { number } from "prop-types";
import config from '../../config.js';
import axios from "axios";
import { toast } from "react-hot-toast";
import { hideLoader, showLoader } from "../../utils/loader";
import Tiframe from "./iFrame";
import { CancelButton } from "../button/button";


export const Four = ({ text, action, getPin, setSupportMessage }) => {
  const [tDData, set3dData] = useState([]);
  const [show3D, setShow3D] = useState(false);
  const input_1 = useRef();
  const input_2 = useRef();
  const input_3 = useRef();
  const input_4 = useRef();

  const [input1, setInput1] = useState(null);
  const [input2, setInput2] = useState(null);
  const [input3, setInput3] = useState(null);
  const [input4, setInput4] = useState(null);

  let pin = `${input1}${input2}${input3}${input4}`;
  //console.log(pin);
  useEffect(() => {
    getPin(`${input1}${input2}${input3}${input4}`);
  }, [pin])
  const SetInput1 = (e) => {
    setInput1(e.target.value.substring(0, 1));
    getPin(`${input1}${input2}${input3}${input4}`);
  }
  const SetInput2 = (e) => {
    setInput2(e.target.value.substring(0, 1));
    getPin(`${input1}${input2}${input3}${input4}`);
  }
  const SetInput3 = (e) => {
    setInput3(e.target.value.substring(0, 1));
    getPin(`${input1}${input2}${input3}${input4}`);
  }
  const SetInput4 = (e) => {
    setInput4(e.target.value.substring(0, 1));
    getPin(`${input1}${input2}${input3}${input4}`);
  }

  const Dispatch = useDispatch();
  const handleChange = (e, newinput, change) => {
    if (e.current.value.length === 1 && !change) {
      newinput.current.focus();
    } else if (change) {
      if(`${input1}${input2}${input3}${input4}`.length === 4){
        if(!isNaN(`${input1}${input2}${input3}${input4}`) && !isNaN(parseFloat(`${input1}${input2}${input3}${input4}`))){
          // Inside here shows the pin is valid number
          //alert("Pin is a valid number");
          Authourize()
          // Dispatach_Card_Navigation(Dispatch, 3);
        }else{
          alert(`${input1}${input2}${input3}${input4} : is the pin`);
        }
      }
    }
  };
  useEffect(() => {
    input_1.current.focus();
  }, []);

  const Authourize = () => {
    //setLoading(true);
    showLoader();
    const tranId = sessionStorage.getItem("tranId");
  
    const paymentData = {
      "pin": pin,
      "transactionId": tranId
    }
   
    const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/authorization`;
    axios.post(API_ENDPOINT, paymentData).then(response => {
      console.log({response});
      // tran ID expired
          //sessionStorage.setItem("expired", true);
      // sessionStorage.removeItem("tranId");
      // sessionStorage.removeItem("product_data");
      hideLoader();
      if(response?.data?.data?.responseCode == "10" || response?.data?.data?.responseCode == "00"){
       /* //console.log('succ', response);
        Swal.fire('Success', response?.data?.data?.message)
        window.open('redirect')*/
        Swal.fire({
          title: "Success",
          text: `${response?.data?.data?.message}`,
          icon: "success",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (result.value) {
            const callback = window.sessionStorage.getItem("fallbackUrl")
            sessionStorage.clear();
            if (typeof(callback) !== 'undefined' && callback != null){
              window.location.href= callback;
            }
            else{
              window.history.go(-1);
            }
          }
        });

      
        // toast.success(response?.data?.data?.message)
        Dispatach_Card_Navigation(Dispatch, 1)
      }
      if(response?.data?.data?.responseCode == "11"){
        setSupportMessage(response?.data?.data?.plainTextSupportMessage)
        Dispatach_Card_Navigation(Dispatch, 4)
      }else if(response?.data?.data?.responseCode == "12" && response?.data?.data?.processor==="ISW"){
        //window.open(response?.data?.data?.TermUrl, '_self')
        set3dData(response?.data?.data)
        setShow3D(true);
        //Dispatach_Card_Navigation(Dispatch, 3)
      } else if(response?.data?.data?.responseCode == "12" && response?.data?.data?.processor==="UP"){
        window.open(response?.data?.data?.url, '_self')
        //Dispatach_Card_Navigation(Dispatch, 3)
      }

      setTimeout(() => {
        //setLoading(false);
        //window.location.href = response.data.data.callbackUrl;
      }, 3000);
    }).catch(error => {
      //console.log('err', error );
      Swal.fire({
        title: "Error",
        text: `${error?.response?.data?.message|| error?.response?.data?.description}`,
        icon: "error",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          if (sessionStorage.getItem('referrer')) {
            window.location.href=sessionStorage.getItem('referrer');
          } else {
            window.history.go(-1);
          }
        }
      });
      toast.error("Error!")
      hideLoader();
      //  setErrMsg(error.response.data.message);
      //setLoading(false);
    });
  }

  const Auth3d = () => {
    //setLoading(true);
    showLoader();
    const tranId = sessionStorage.getItem("tranId");
    const cardEncrypt = sessionStorage.getItem("cardEncrypt");

  
    const cancelData = {
      "cardEncrypt": cardEncrypt,
      "otp": null,
      "tranId": tranId
    }
    const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/processing`;
    axios.post(API_ENDPOINT, cancelData).then(response => {
      sessionStorage.setItem("expired", true);
      // sessionStorage.removeItem("tranId");
      // sessionStorage.removeItem("product_data");
      hideLoader()
      Swal.fire({
        title: "Success",
        text: `${response?.data?.message}`,
        icon: "success",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.value) {
          const callback = window.sessionStorage.getItem("fallbackUrl")
          sessionStorage.clear();
          if (typeof(callback) !== 'undefined' && callback != null){
            window.location.href= callback;
          }
          else{
            console.log({ reg: sessionStorage.getItem('referrer') })
            if (sessionStorage.getItem('referrer')) {
              window.location.href(sessionStorage.getItem('referrer'));
            } else {
              window.history.go(-3);
            }
            //  window.history.go(-1);
          }
        }
      });
    }).catch(error => {
      hideLoader();
      //setErrMsg(error.response.data.message);
      //console.log(error)
    });

  }

  return (
    <div className={`${styles.container}`}>
       {
        !show3D ? 
        <>
        <BoldText align={"center"}>
            Kindly provide your 4-digit card pin to authorize this payment
        </BoldText>
        <div className={`${styles.boxContainer}`}>
          
        <InputText type="password" maxlength="1" reference={input_1} onInput={SetInput1} onchange={(e) => handleChange(input_1, input_2, false)} />
        <InputText type="password" maxlength="1" reference={input_2} onInput={SetInput2} onchange={(e) => handleChange(input_2, input_3, false)} />
        <InputText type="password" maxlength="1" reference={input_3} onInput={SetInput3} onchange={(e) => handleChange(input_3, input_4, false)} />
        <InputText type="password" maxlength="1" reference={input_4} onInput={SetInput4} onchange={(e) => handleChange(input_4, input_2, true)} />
      </div>
      <CancelButton text="cancel" onclick={() => Dispatach_Card_Navigation(Dispatch, 1)} />
      </>   
      : <>
      <Frame style={{ height: 500 }} >        
     <Tiframe 
           tDData={tDData}
           showModal={show3D}
           hideModal={setShow3D}
           /> 
      </Frame>
      <CancelButton text="cancel" onclick={Auth3d} />
      </>
      }
    </div>
  );
};
