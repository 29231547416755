import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SVGS } from "../../assets/svgs";
import { Dispatach_Card_Navigation } from "../../redux/actions/navigations";
import { Dispatach_All_Card_Details } from "../../redux/actions/payment";
import {
  ITEM_CARD_EXPIRYDATE,
  ITEM_CARD_CVV,
  ITEM_CARD_HOOLDERS_NAME,
  ITEM_CARD_NUMBER,
  CARD_PIN,
} from "../../redux/store/types";
import { SideCardcontent } from "../all-containers/general";
import { Button, CancelButton } from "../button/button";
import { Checkbox } from "../checkbox/checkbox";
import { Four } from "../four_digits/Four";
import { IconWrapper } from "../iconWrapper/wrapper";
import { InputText, InputText2, InputDate, InputCVV, InputPin } from "../inputs/inputs";
import { LightText } from "../lightText/lighttext";
import {
  getCreditCardNameByNumber
} from 'creditcard.js';
// import { InProccess } from "../modal/container";
import { BoldText, NormalText } from "../normalText/normalText";
import { Prize } from "../prize/prize";
import { Title } from "../titles/titles";
import styles from "./card.module.css";
import config from '../../config.js';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import CryptoJS from "crypto-js";
import { Six } from "../six_digits/six";
import Swal from "sweetalert2";
import { hideLoader, showLoader } from "../../utils/loader";

const { auth } = SVGS;
export const CardContents = ({ modals, overTabs }) => {
  const [tab, setTab] = useState(1);
  const Dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const navigation = useSelector(state => state.navigation.card)
  const cardDetails = useSelector((state) => state.card);
  const [btnStatus, setBtnStatus] = useState(false);

  const [cardNumber, setCardNumber] = useState(null);
  const [pin, setPin] = useState(null);
  const [expiry, setExpiry] = useState(null);
  const [cVV, setCVV] = useState(null);
  const [encrytedData, setEncrytedData] = useState(null);
  const [scheme, setScheme] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [systemInfo, setSystemInfo] = useState(null);
  const [supportMessage, setSupportMessage] = useState('')
  const [processor, setProcessor] = useState('')
  const [linkType, setLinkType] = useState('');
  const [recurrentPayment, setRecurrentPayment] = useState(false);
  const [merchantId, setMerchantId] = useState(false);



  const handleCardNumber = (e) => {
    setScheme(getCreditCardNameByNumber(e.target.value))
    setCardNumber(JSON.stringify(e.target.value).replace(/\s/g, ""));
    Dispatach_All_Card_Details(Dispatch, ITEM_CARD_NUMBER, e.target.value);
    ActivateButton();
  }
  const handleExpiryDate = (e) => {
    setExpiry(JSON.stringify(e.target.value).substring(0, 5).replace(/\s/g, ""));
    Dispatach_All_Card_Details(Dispatch, ITEM_CARD_EXPIRYDATE, e.target.value.substring(0, 5));
    ActivateButton();
  }
  const handleCvv = (e) => {
    if (e.target.value.length <= 3) {
      setCVV(JSON.stringify(e.target.value).replace(/\s/g, ""));
      Dispatach_All_Card_Details(Dispatch, ITEM_CARD_CVV, e.target.value);
      ActivateButton();
    }
  }
  const handlePin = (e) => {
    if (e.target.value.length === 4) {
      setPin((e.target.value).replace(/\s/g, ""));
      Dispatach_All_Card_Details(Dispatch, CARD_PIN, e.target.value);
      ActivateButton();
    }
  }
  const getPin = (pin) => {
    //console.log(pin);
    setPin(pin);
  }
  const handleName = (e) => {
    // //console.log(e.target.value)
    Dispatach_All_Card_Details(Dispatch, ITEM_CARD_HOOLDERS_NAME, e.target.value)
  }

  const ActivateButton = ()  => {
    if (cardDetails?.card?.card_number?.replace(/\s/g, "").length > 10 && cardDetails?.card?.cvv?.length > 1 && cardDetails.card.expiry_date) {
        setBtnStatus(true);
    }else{
      setBtnStatus(false);
    }
  }

  useEffect(() => {
    var _navigator = {};
    for (var i in navigator) _navigator[i] = navigator[i];
    setSystemInfo(()=> _navigator);
    if(sessionStorage.getItem("expired")){
      const fallbackUrl = sessionStorage.getItem("fallbackUrl");
      sessionStorage.clear();
      if (typeof(fallbackUrl) !== 'undefined' && fallbackUrl != null){
        window.location.href= fallbackUrl;
      }
      else{
        window.history.go(-1);
      }
    }
}, []);

useEffect(()=>{
  if (window.sessionStorage.getItem("product_data")) {
    //console.log('jsonn', JSON.parse(sessionStorage.getItem("product_data")))
    setLinkType(JSON.parse(sessionStorage.getItem("product_data"))?.paymentLinkType);
    setRecurrentPayment(JSON.parse(sessionStorage.getItem("product_data"))?.recurrentPayment);
  }
}, [])

 const AESEncryption = (plaintext, password) => {
   const key = CryptoJS.enc.Utf8.parse(password);
   const cipheredText = CryptoJS.AES.encrypt(plaintext, key).toString();
   return cipheredText;
  //  const decipheredText = CryptoJS.AES.decrypt(cipheredText, key).toString();
 }
 const RSAEncryption = (plaintext, password) => {
   const key = CryptoJS.enc.Utf8.parse(password);
   const cipheredText = CryptoJS.AES.encrypt(plaintext, key).toString();
   return cipheredText;
  //  const decipheredText = CryptoJS.AES.decrypt(cipheredText, key).toString();
 }
 const handleRecurrent = async(data) => {
  showLoader();
  const API_ENDPOINT3 = `${config.API_ROOT_ADDRESS}/api/v1/transaction/recurrent/pay`;
  try{
  await axios.post(API_ENDPOINT3, data).then(response2 => {
    hideLoader();
    setLoading(false);      
      Swal.fire({
      title: "Success",
      text: `${response2?.data?.message}`,
      icon: "success",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.value) {
        window.open('redirect', '_self')
      }
    });
    //console.log('res', response2);
  }).catch(error => {
    hideLoader();
    //console.log(error.response.data);

    setErrMsg(error?.response?.data?.message || error?.response?.data?.description);
    setLoading(false);
  });
}catch(e){
  //console.log('eer', e)
}
 }

  const payNow = async() => {
    if(btnStatus){
      setLoading(true);
      showLoader();
      const product_data = JSON.parse(sessionStorage.getItem("product_data"));
      const cardNum = {
        encryptString: `${cardDetails.card.card_number.replace(/\s/g, "")}|${cardDetails.card.cvv}`,
        merchantPublicKey: sessionStorage.getItem("PUBLIC_KEY")
      }
      if (cardDetails.card.card_number.replace(/\s/g, "").length > 10 && cardDetails.card.cvv.length === 3 && cardDetails.card.expiry_date) {
        const month = `${cardDetails.card.expiry_date[0]}${cardDetails.card.expiry_date[1]}`;
        if (month <= 12 && month > 0) {
          
          const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/card/encryption`;
          axios.post(API_ENDPOINT, cardNum).then(async response => {
            setErrMsg(null);
            setScheme(scheme);
            setEncrytedData(response.data.data);
  
            const paymentData = {
              cardholder: '',
              "encryptCardNo": response.data.data,
              expiry: cardDetails.card.expiry_date,
              mobile: product_data?.customer?.phoneNumber,
              pin: '',
              //deviceInformation: JSON.stringify(systemInfo),
              tranId: sessionStorage.getItem("tranId"),
              scheme: scheme,
              wayaPublicKey: sessionStorage.getItem("PUBLIC_KEY"),
              merchantId: product_data?.customer?.merchantId
            }

            const reccurentData = {              
                "cvv2": cardDetails.card.cvv,
                "expiryDate": cardDetails.card.expiry_date.replace('/',''),
                "pan": JSON.parse(cardNumber),
                "pin": pin,
                "transactionRef": sessionStorage.getItem("tranId"),             
                 merchantId: product_data?.customer?.merchantId
              }
            
            //console.log('type', product_data?.paymentLinkType);
            sessionStorage.setItem("cardEncrypt", JSON.parse(cardNumber));
            const data11 = {
              "cardholder":  product_data?.customer?.name,
              encryptCardNo: JSON.parse(cardNumber),
              "expiry": cardDetails.card.expiry_date.replace('/',''),
              "mobile": product_data?.customer?.phoneNumber,
              "pin": null,
              "recurrentPayment": false,
              "scheme": scheme,
              "securityCode": cardDetails.card.cvv,
              "tranId": sessionStorage.getItem("tranId"),
              "wayaPublicKey": sessionStorage.getItem("PUBLIC_KEY"),
              merchantId: product_data?.customer?.merchantId
            }
            const API_ENDPOINT2 = `${config.API_ROOT_ADDRESS}/api/v1/transaction/payment`;
            const API_ENDPOINT3 = `${config.API_ROOT_ADDRESS}/api/v1/transaction/recurrent`;

            try{
              if(product_data?.paymentLinkType === 'SUBSCRIPTION_PAYMENT_LINK'){
            await axios.post(API_ENDPOINT3, reccurentData).then(response2 => {
              setLoading(false);
              hideLoader();
                if(response2?.data?.status === true){
                  handleRecurrent(response2?.data?.data)
                }
              /*  Swal.fire({
                title: "Success",
                text: `${response2?.data?.message}`,
                icon: "success",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.value) {
                  window.open('redirect', '_self')
                }
              });
      
            
            /* // toast.success(response?.data?.data?.message)
              Dispatach_Card_Navigation(Dispatch, 1)

              setProcessor(response2?.data?.data?.processor);
              Dispatach_Card_Navigation(Dispatch, 2);
              // setExpiry(JSON.parse(expDate));
              setEncrytedData(response2?.data?.body?.data);*/
              //console.log('res', response2);
            }).catch(error => {
              //console.log(error.response.data);

              setErrMsg(error?.response?.data?.message || error?.response?.data?.description);
              setLoading(false);
              hideLoader();
            });
          }else{
            await axios.post(API_ENDPOINT2, data11).then(response2 => {
              setLoading(false);
              hideLoader();
              setProcessor(response2?.data?.data?.processor);
              Dispatach_Card_Navigation(Dispatch, 2);
              // setExpiry(JSON.parse(expDate));
              setEncrytedData(response2?.data?.body?.data);
              //console.log('res', response2);
            }).catch(error => {
              //console.log(error.response.data);
              if (sessionStorage.getItem('referrer')) {
                window.location.href=sessionStorage.getItem('referrer');
              } else {
                window.history.go(-1);
              }
              setErrMsg(error?.response?.data?.message || error?.response?.data?.description);
              setLoading(false);
              hideLoader();
            });
          }
          }catch(e){
            //console.log('eer', e)
          }


          }).catch(err => {
            setErrMsg(err.response.data.message);
            setLoading(false);
            hideLoader();
          })
          // end of the card option
        } else {
          setErrMsg("Invalid expiry date");
          setLoading(false);
          hideLoader();
        }
      } else {
        setErrMsg("All fields are required!!!");
        setLoading(false);
        hideLoader();
      }
    }else {
      setErrMsg("All fields are required!!!");
      setLoading(false);
      hideLoader();
    }
  }

  const Authenticate = () => {
    setLoading(true);

    const tranId = sessionStorage.getItem("tranId");
    const product_data = sessionStorage.getItem("product_data");
    if (!product_data || !tranId) {
      sessionStorage.removeItem("fallbackUrl");
      window.location.href = config.LOGIN_URL;
    }
    const paymentData = {
      cardEncrypt: encrytedData,
      tranId: tranId
    }
    const API_ENDPOINT = `${config.API_ROOT_ADDRESS}/api/v1/transaction/processing`;
    axios.post(API_ENDPOINT, paymentData).then(response => {
      // tran ID expired
          sessionStorage.setItem("expired", true);
      // sessionStorage.removeItem("tranId");
      // sessionStorage.removeItem("product_data");
      setTimeout(() => {
        setLoading(false);
        window.location.href = response.data.data.callbackUrl;
      }, 3000);
    }).catch(error => {
      setErrMsg(error.response.data.message);
      setLoading(false);
    });
  }
  
  return (
    <ContentsContainer>
      {navigation.step === 1 && (
        <>
          <div className={styles.toughContainer}>
            <Title title="Enter your card details to pay" />
            <div className={styles.content}>
              <InputText2 label="CARD NUMBER" placeholder="0000 0000 0000" onChange={handleCardNumber} />
              <div className={styles.inputSecondContainer}>
                <div className={styles.shortInput}>
                  <InputDate label="EXPIRY DATE" placeholder="MM/YY" onChange={handleExpiryDate} />
                </div>
                <div className={styles.shortInput}>
                  <InputCVV label="CVV" type={'password'} placeholder="123" onChange={handleCvv} />
                </div>
              </div>
              {(linkType === 'SUBSCRIPTION_PAYMENT_LINK' || recurrentPayment) &&
              <div className={styles.inputSecondContainer}>
                <div className={styles.shortInput}>
                  <InputPin label="PIN" type={'password'} placeholder="1234" onChange={handlePin} />
                </div>
              </div>}
              {/* <InputText onchange={handleName} label="Cardholder’s Name" /> */}
            </div>
          </div>
          <p className={`${errMsg ? 'flex' : 'hidden'} mt-3`}
          style={{paddingLeft: 20, fontSize: 14}}>
            <span>
              <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M8.66634 8.125H7.33301V4.375H8.66634V8.125ZM8.66634 10.625H7.33301V9.375H8.66634V10.625ZM7.99967 1.25C7.1242 1.25 6.25729 1.41166 5.44845 1.72575C4.63961 2.03984 3.90469 2.50022 3.28563 3.08058C2.03539 4.25269 1.33301 5.8424 1.33301 7.5C1.33301 9.1576 2.03539 10.7473 3.28563 11.9194C3.90469 12.4998 4.63961 12.9602 5.44845 13.2742C6.25729 13.5883 7.1242 13.75 7.99967 13.75C9.76779 13.75 11.4635 13.0915 12.7137 11.9194C13.964 10.7473 14.6663 9.1576 14.6663 7.5C14.6663 6.67924 14.4939 5.86651 14.1589 5.10823C13.8238 4.34994 13.3328 3.66095 12.7137 3.08058C12.0947 2.50022 11.3597 2.03984 10.5509 1.72575C9.74206 1.41166 8.87515 1.25 7.99967 1.25Z" fill="#FF6700"></path></svg>
            </span>
            <span className="text-primary-theme ml-2 text-xs">{errMsg}</span>
          </p>
          <div className={styles.content}>
            <Button loading={loading} text="Pay Now"
              onClick={() => {
                payNow();
              }} active={btnStatus} />

            <Checkbox />
          </div>
        </>
      )}
      {/* FOR THE DIGIT PART */}
      {navigation.step === 2 && (
        <div className={`${styles.forContainer}`}>          
          <div className={styles.iswContainer}>
            <Four action={setTab} setSupportMessage={setSupportMessage} getPin={getPin} />
          </div>
        </div>
      )}
       {navigation.step === 4 && (
        <div className={`${styles.forContainer}`}>
          <BoldText align={"center"}>
            Kindly provide your 6-digit OTP to authorize this payment
          </BoldText>
          <div className={styles.subFourContainer}>
            <Six supportMessage={supportMessage} processor={processor} cardNumber={cardNumber} action={setTab} getPin={getPin} />
          </div>

          <CancelButton text="cancel" onclick={() => Dispatach_Card_Navigation(Dispatch, 1)} />
        </div>
      )}
      {/* FOR THE AUTHENTICATION  */}
      {navigation.step === 3 && (
        <div className={`${styles.forContainer}`}>
          <div style={{ marginBottom: 10 }}>
            <IconWrapper pad={20}>
              <img src={auth} alt="" />
            </IconWrapper>
          </div>

          <BoldText align={"center"}>
            Kindly click the button below to authenticate with your bank
          </BoldText>
          <div className={styles.handlebutton}>
            <Button text="Authenticate" loading={loading} active={true} onClick={Authenticate} />
            <CancelButton text="cancel" onclick_={() => modals("otp")} />
          </div>
        </div>
      )}
    </ContentsContainer>
  );
};

export const ContentsContainer = ({ children }) => {
  const navigate = useNavigate();
  function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  const [email, setEmail] = useState(null);
  const [amount, setAmount] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState(null)

  useEffect(() => {
    if (window.sessionStorage.getItem("product_data")) {
      setEmail(JSON.parse(window.sessionStorage.getItem("product_data")).customer?.email);
      setAmount(JSON.parse(window.sessionStorage.getItem("product_data")).amount);
      setPhoneNumber(JSON.parse(window.sessionStorage.getItem("product_data")).customer?.phoneNumber);
    }
  }, [children]);

  return (
    <SideCardcontent>
      <div className={styles.general_cont}>
        <div style={{ paddingRight: '10px' }}>
          {/* <div className={styles.textContainer}> */}
            {/* <NormalText>Amount Payable is</NormalText> */}
            {/* <NormalText>{email}</NormalText> */}
            {/* <LightText>{email}</LightText> */}
          {/* </div> */}
          <div className={styles.prize}>
            <Prize>{amount ? `${numberWithCommas(JSON.stringify(amount))}.00` : ''}</Prize>
            <p style={{fontSize: "14px",paddingTop: "12px", color: "#999"}}>{email}</p>
          </div>
        </div>
        {children}
      </div>
    </SideCardcontent>
  );
};
